import { ReactNode } from "react"
import { ResourceTypes } from "kui-crm"
import { ContractTypes } from "../common"
import { AccessPermissionActions } from "../store/appStore"

export enum Routes {
  main = "/",
  apartment_overview = "/apartments/:id/overview",
  apartment_ownership = "/apartments/:id/ownership",
  apartment_inspections = "/apartments/:id/inspections",
  apartment_inspection = "/apartments/:id/inspections/:inspection_id",
  apartment_descriptions = "/apartments/:id/descriptions",
  apartment_expenses_summary = "/apartments/:id/expenses/summary",
  apartment_expenses_meteringDevices = "/apartments/:id/expenses/metering-devices",
  apartment_expenses_services = "/apartments/:id/expenses/services",
  apartment_expenses_documents = "/apartments/:id/expenses/attachments",
  apartment_expenses = "/apartments/:id/expenses",
  apartment_photo = "/apartments/:id/photo",
  apartment_promotion = "/apartments/:id/promotion",
  apartment_price_prediction = "/apartments/:id/price-prediction",
  apartment_documents = "/apartments/:id/documents",
  apartment = "/apartments/:id",
  apartments = "/apartments",
  user_overview = "/users/:id/overview",
  user_data_passport = "/users/:id/data/passport",
  user_data_employment = "/users/:id/data/employment",
  user_data_bank = "/users/:id/data/bank",
  user_data = "/users/:id/data",
  client = "/users/:id",
  clients = "/users",
  signIn = "/signin",
  company = "/companies/:id",
  companies = "/companies",
  tariffsGroups = "/functions/tariffs_groups",
  waterTariffsGroups = "/functions/tariffs_groups/water",
  electricityTariffsGroups = "/functions/tariffs_groups/electricity",
  gasTariffsGroups = "/functions/tariffs_groups/gas",
  heatingTariffsGroups = "/functions/tariffs_groups/heating",
  waterTariffsGroup = "/functions/tariffs_groups/water/:id",
  electricityTariffsGroup = "/functions/tariffs_groups/electricity/:id",
  gasTariffsGroup = "/functions/tariffs_groups/gas/:id",
  heatingTariffsGroup = "/functions/tariffs_groups/heating/:id",
  meteringDevices = "/functions/metering_devices",
  waterMeteringDevices = "/functions/metering_devices/water",
  electricityMeteringDevices = "/functions/metering_devices/electricity",
  gasMeteringDevices = "/functions/metering_devices/gas",
  heatingMeteringDevices = "/functions/metering_devices/heating",
  waterMeteringDevice = "/functions/metering_devices/water/:id",
  electricityMeteringDevice = "/functions/metering_devices/electricity/:id",
  gasMeteringDevice = "/functions/metering_devices/gas/:id",
  heatingMeteringDevice = "/functions/metering_devices/heating/:id",
  functions = "/functions",
  contracts = "/contracts",
  service_contract = "/contracts/service-contracts/:id",
  service_contracts = "/contracts/service-contracts",
  rental_contract = "/contracts/rental-contracts/:id",
  rental_contracts = "/contracts/rental-contracts",
  registries = "/functions/registry",
  expensesRegistry = "/functions/registry/expenses",
  inspectionsRegistry = "/functions/registry/inspections",
  tenantAppraisalsRegistry = "/functions/registry/tenant_appraisals",
  landlordAppraisalsRegistry = "/functions/registry/landlord_appraisals",
  insurancesRegistry = "/functions/registry/insurances",
  proxiesRegistry = "/functions/registry/proxies",
  metersVerificationsRegistry = "/functions/registry/meters_verifications",
  documentsRegistry = "/functions/registry/documents",
  paymentsRegistry = "/functions/registry/payments",
}

export const DynamicRoutes = {
  apartment: (id: number) => `${Routes.apartments}/${id}`,
  client: (id: number) => `${Routes.clients}/${id}`,
  inspections: (id: number) => `${Routes.apartments}/${id}?tab=inspections`,
  inspection: (apartmentId: number, inspectionId: number) =>
    `${Routes.apartments}/${apartmentId}?tab=inspections&id=${inspectionId}`,
  meter: (resource: ResourceTypes, id: number) =>
    `${Routes.meteringDevices}/${resource}/${id}`,
  company: (id: number) => `${Routes.companies}/${id}`,
  contract: (id: number, type: ContractTypes) =>
    `${
      type === "rental" ? Routes.rental_contracts : Routes.service_contracts
    }/${id}`,
  metersExpenses: (id: number) =>
    `${Routes.apartments}/${id}?tab=expenses&subtab=metering-devices`,
}

export enum RouteAuthTypes {
  loggedIn = "loggedIn",
  loggedOut = "loggedOut",
  free = "free",
}

export type RouteParams = {
  id?: number
  title?: string
  path: string
  content: ReactNode
  auth: RouteAuthTypes
  permission?: AccessPermissionActions
}

export type HeaderRouteParams = {
  title: string
  path: string
  permission?: AccessPermissionActions
}
