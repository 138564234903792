import React from "react"
import styled from "@emotion/styled"
import { Caption } from "kui-basic"
import { Tooltip } from "kui-crm"
import { css } from "@emotion/react"
import { DropdownOptionProps, OptionStylesProps } from "./types"

const DropdownOption = (props: DropdownOptionProps) => {
  const { option, menuItemStyles, handleOptionClick } = props

  const handleClick = () => {
    if (!option.disabledReason) handleOptionClick(option.handleClick)
  }

  const Option = () => (
    <StyledMenuItem
      role="button"
      className={menuItemStyles}
      onClick={handleClick}
      onKeyDown={handleClick}
      tabIndex={0}
      key={option.label}
      disabled={!!option.disabledReason}
    >
      <Caption
        size="xs"
        weight={500}
        color={option.disabledReason ? "fiftyP" : "seventy"}
      >
        {option.label}
      </Caption>
    </StyledMenuItem>
  )

  if (option.disabledReason) {
    return (
      <Tooltip
        content={
          <Caption size="xs" color="fiftyP">
            {option.disabledReason}
          </Caption>
        }
        placement="bottom"
      >
        <Option />
      </Tooltip>
    )
  }

  return <Option />
}

export default DropdownOption

const notDisabledStyles = ({ disabled, theme }: OptionStylesProps) =>
  !disabled &&
  css`
    cursor: pointer;
    &:hover {
      background-color: ${theme?.palette.background.light1};
    }
  `

const StyledMenuItem = styled.div<OptionStylesProps>`
  border-radius: 12px;
  padding: 13px 8px;
  transition: all ease-out 0.2s;
  text-align: left;
  ${notDisabledStyles};
`
