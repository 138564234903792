import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import { Loader, resHandler } from "kui-utils"
import ApartmentSummaryStore from "./summary/ApartmentSummary"
import ApartmentServicesStore from "./services/ApartmentServices"
import ApartmentMetersStore from "./meters/ApartmentMeters"
import HostStore from "../../../../../store/Root"
import ApartmentPageStore from "../../../store/ApartmentPageStore"
import DocumentsWithPaginatorStore from "../../../../../store/templates/DocumentsWithPaginatorStore"
import {
  ClientAttachmentsAgent,
  InternalAttachmentsAgent,
} from "../../../../../agent/ApartmentAttachments"
import ApartmentLiteServicesStore from "../../../../../store/shared/apartmentService/ApartmentServicesStore"
import { LastOpenPeriodParams } from "../../../../../types/store/apartmentExpenses"
import ApartmentExpensesPeriods from "./ApartmentExpensesPeriods"

class ApartmentExpensesStore {
  date: DateTime | null

  lastOpenPeriod: DateTime | null

  summaryStore: ApartmentSummaryStore

  servicesStore: ApartmentServicesStore

  metersStore: ApartmentMetersStore

  clientsAttachmentsStore: DocumentsWithPaginatorStore

  internalAttachmentsStore: DocumentsWithPaginatorStore

  periodsStore: ApartmentExpensesPeriods

  apartmentStore: ApartmentPageStore

  loader: Loader

  private hostStore: HostStore

  constructor(hostStore: HostStore, apartmentStore: ApartmentPageStore) {
    this.hostStore = hostStore
    this.apartmentStore = apartmentStore
    this.date = null
    this.lastOpenPeriod = null
    this.summaryStore = new ApartmentSummaryStore(this)
    this.servicesStore = new ApartmentServicesStore(this)
    this.metersStore = new ApartmentMetersStore(apartmentStore, this)
    this.clientsAttachmentsStore = new DocumentsWithPaginatorStore(
      ClientAttachmentsAgent
    )
    this.internalAttachmentsStore = new DocumentsWithPaginatorStore(
      InternalAttachmentsAgent
    )
    this.periodsStore = new ApartmentExpensesPeriods(this)
    this.loader = new Loader(true)
    makeAutoObservable(this, { apartmentStore: false })
  }

  fetchLastOpenPeriod = async (apartmentId: number) => {
    this.loader.startLoading()

    const response = await ApartmentLiteServicesStore.getLastOpenedPeriod(
      apartmentId
    )
    resHandler(
      response,
      this.loader,
      ({ period }: LastOpenPeriodParams) => {
        if (period) {
          this.lastOpenPeriod = period
          this.date = period
        }
      },
      "getting an open period",
      {
        withEndLoading: false,
      }
    )
  }

  setDate = (date: DateTime) => {
    this.date = date
  }

  updateExpensesPage = () => {
    this.date = null
    this.summaryStore = new ApartmentSummaryStore(this)
    this.servicesStore = new ApartmentServicesStore(this)
    this.metersStore = new ApartmentMetersStore(this.apartmentStore, this)
    this.clientsAttachmentsStore = new DocumentsWithPaginatorStore(
      ClientAttachmentsAgent
    )
    this.internalAttachmentsStore = new DocumentsWithPaginatorStore(
      InternalAttachmentsAgent
    )
    this.periodsStore = new ApartmentExpensesPeriods(this)
    this.loader = new Loader(true)
  }

  get overviewStore() {
    return this.apartmentStore.overviewStore
  }
}

export default ApartmentExpensesStore
