import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import styled from "@emotion/styled"
import useInspectionStore from "./store"
import InspectionHeader from "./components/InspectionHeader"
import InitialInspectionInfo from "./variants/InitialInspectionInfo"
import { getQueryParam } from "../../../../utils/service/functions"
import Loading from "../../../../components/common/Loading"
import RegularInspectionInfo from "./variants/RegularInspectionInfo"
import ImpairmentsInspectionInfo from "./variants/ImpairmentsInspectionInfo"
import AppraisalInspectionInfo from "./variants/AppraisalInspectionInfo"
import InventoryInspectionInfo from "./variants/InventoryInspectionInfo"
import TransferInspectionInfo from "./variants/TransferInspectionInfo"

const InspectionByType = {
  initial: <InitialInspectionInfo />,
  inventory: <InventoryInspectionInfo />,
  transfer: <TransferInspectionInfo />,
  regular: <RegularInspectionInfo />,
  appraisal: <AppraisalInspectionInfo />,
  impairments: <ImpairmentsInspectionInfo />,
}

const InspectionPage = () => {
  const inspectionStore = useInspectionStore()
  const inspectionId = Number(getQueryParam("id"))
  const { loader, actionLoader, fetchInspection, clearInspectionPage } =
    inspectionStore
  const inspectionsLoader =
    inspectionStore.apartmentStore.inspectionsStore.actionLoader

  useEffect(() => {
    fetchInspection(inspectionId)

    return () => clearInspectionPage()
  }, [inspectionId])

  if (loader.isLoading) {
    return <Loading />
  }

  return (
    <StyledWrapper>
      <InspectionHeader />
      {inspectionStore.type && InspectionByType[inspectionStore.type]}

      <LoaderState loader={actionLoader} />
      <LoaderState loader={inspectionsLoader} />
      <LoaderState loader={loader} onlyError />
    </StyledWrapper>
  )
}

export default observer(InspectionPage)

const StyledWrapper = styled.div`
  padding-bottom: 40px;
`
