import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Loader } from "kui-utils"
import { CabinetUserModel } from "../../types/api/cabinet"
import CabinetAgent from "../../agent/Cabinet"
import RootStore from "../Root"
import { AccessPermissionActions } from "../../types/store/appStore"

class CabinetStore {
  rootStore: RootStore

  user: CabinetUserModel | null

  accessPermissions: AccessPermissionActions[]

  loader: Loader

  actionLoader: Loader

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.user = null
    this.accessPermissions = []
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    makeAutoObservable(this, { rootStore: false })
  }

  getUser = async () => {
    this.actionLoader.startLoading()
    const [err, res] = await to(CabinetAgent.getUser())
    runInAction(() => {
      if (!err && res) {
        this.user = res
      } else {
        this.actionLoader.setError("get user info", err)
      }
    })
    this.actionLoader.endLoading()
  }

  fetchAccessPermissions = async () => {
    this.loader.startLoading()
    this.accessPermissions = []
    this.loader.endLoading()
  }

  updateAccessPermissions = (data: AccessPermissionActions[]) => {
    this.accessPermissions = data
  }

  isThereAccess = (permission: AccessPermissionActions) => {
    if (!this.accessPermissions.length) return true

    return this.accessPermissions.includes(permission)
  }

  get fullName() {
    return this.user?.first_name || this.user?.last_name
      ? `${this.user?.first_name ?? ""} ${this.user?.last_name ?? ""}`
      : this.user?.username || ""
  }

  get role() {
    return this.user?.user_role
  }

  reset = () => {
    this.accessPermissions = []
  }
}

export default CabinetStore
