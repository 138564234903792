import React from "react"
import { HomeIcon, LockIcon, RubleIcon } from "kui-icon"
import { addToArrayByCondition } from "kui-utils"
import { Routes } from "../../../../../types/route"
import AutoMetersCard from "../../../components/cards/AutoMetersCard"
import AccountantDashboardStore from "../../../store/variants/AccountantDashboardStore"
import { LinkCardProps } from "../../../components/common/DashboardLinkCard/types"
import CompaniesRequisitesCard from "../../../components/cards/CompaniesRequisitesCard"
import ApartmentsServicesCard from "../../../components/cards/ApartmentsServicesCard"
import ClosingContractsCard from "../../../components/cards/ClosingContractsCard"
import MetersWithDeviatingCard from "../../../components/cards/MetersWithDeviatingCard"
import MetersVerificationsCard from "../../../components/cards/MetersVerificationsCard"

export const getLinkCards = (
  accountantDashboard: AccountantDashboardStore
): LinkCardProps[] => [
  ...addToArrayByCondition(accountantDashboard.tenantOverduePayments !== null, {
    label: "Overdue tenant payments",
    value: accountantDashboard.tenantOverduePayments,
    icon: <RubleIcon />,
    link: `${Routes.rental_contracts}?sort=renter_payment_status`,
  }),
  ...addToArrayByCondition(
    accountantDashboard.landlordOverduePayments !== null,
    {
      label: "Overdue landlord payments",
      value: accountantDashboard.landlordOverduePayments,
      icon: <RubleIcon />,
      link: `${Routes.service_contracts}?sort=agent_report_payment_status`,
    }
  ),
  ...addToArrayByCondition(accountantDashboard.notClosedPeriods !== null, {
    label: "Not closed periods",
    value: accountantDashboard.notClosedPeriods,
    icon: <LockIcon />,
    link: `${Routes.apartments}?sort=period_status`,
  }),
  ...addToArrayByCondition(accountantDashboard.overdueInsurances !== null, {
    label: "Overdue insurances",
    value: accountantDashboard.overdueInsurances,
    icon: <HomeIcon />,
    link: `${Routes.insurancesRegistry}?sort=expiration_date`,
  }),
]

export const getListCards = (accountantDashboard: AccountantDashboardStore) => [
  ...addToArrayByCondition(
    accountantDashboard.apartmentsServices?.value !== null,
    {
      key: "apartmentsServices",
      card: (
        <ApartmentsServicesCard
          apartmentsStore={accountantDashboard.apartmentsServices!}
        />
      ),
    }
  ),
  ...addToArrayByCondition(
    accountantDashboard.metersWithDeviating?.value !== null,
    {
      key: "metersWithDeviating",
      card: (
        <MetersWithDeviatingCard
          metersStore={accountantDashboard.metersWithDeviating!}
        />
      ),
    }
  ),
  ...addToArrayByCondition(
    accountantDashboard.metersVerifications.value !== null,
    {
      key: "metersVerifications",
      card: (
        <MetersVerificationsCard
          metersStore={accountantDashboard.metersVerifications}
        />
      ),
    }
  ),
  ...addToArrayByCondition(
    accountantDashboard.companiesRequisites?.value !== null,
    {
      key: "companiesRequisites",
      card: (
        <CompaniesRequisitesCard
          companiesStore={accountantDashboard.companiesRequisites!}
        />
      ),
    }
  ),
  ...addToArrayByCondition(accountantDashboard.autoMeters.value !== null, {
    key: "autoMeters",
    card: <AutoMetersCard metersStore={accountantDashboard.autoMeters} />,
  }),
  ...addToArrayByCondition(
    accountantDashboard.closingRentalContracts.value !== null,
    {
      key: "closingRentalContracts",
      card: (
        <ClosingContractsCard
          contractsStore={accountantDashboard.closingRentalContracts}
        />
      ),
    }
  ),
  ...addToArrayByCondition(
    accountantDashboard.closingServiceContracts.value !== null,
    {
      key: "closingServiceContracts",
      card: (
        <ClosingContractsCard
          contractsStore={accountantDashboard.closingServiceContracts}
        />
      ),
    }
  ),
]
