import React from "react"
import { observer } from "mobx-react"
import { Caption, Tooltip } from "kui-basic"
import EditButton from "../../../../../components/ui/EditButton"
import useApartmentStore from "../../../store"

function ApartmentEditButton() {
  const { editor, disabledReason } = useApartmentStore()

  if (disabledReason) {
    return (
      <Tooltip
        strategy="fixed"
        spacing="16px 0 0 0"
        content={
          <Caption data-testid="disabledReason" size="xs">
            {disabledReason}
          </Caption>
        }
      >
        <EditButton data-testid="disabledEditButton" disabled />
      </Tooltip>
    )
  }

  return (
    <EditButton
      isEditing={editor.isEditing}
      onSaveHandler={editor.allowToSendForm}
      onEditHandler={editor.startEditing}
    />
  )
}

export default observer(ApartmentEditButton)
