import React from "react"
import { observer } from "mobx-react"
import ApartmentInspectionsInfo from "./components/ApartmentInspectionsInfo"
import InspectionPage from "../../../pages/InspectionPage"
import useQueryParam from "../../../../../hooks/useQueryParam"
import ProtectedNode from "../../../../../components/common/ProtectedNode"

function ApartmentInspectionsTab() {
  const displayInspection = useQueryParam("id")

  return displayInspection ? (
    <ProtectedNode permission="objectInspectionPage">
      <InspectionPage />
    </ProtectedNode>
  ) : (
    <ApartmentInspectionsInfo />
  )
}

export default observer(ApartmentInspectionsTab)
