import { FormBottom } from "kui-crm"
import React from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Caption } from "kui-basic"
import useApartmentLiteStore from "../../../../../../../store/shared/apartment"
import { ImpairmentConfirmStepProps } from "./types"
import { ImpairmentInfoStepFields } from "../ImpairmentInfoStep/types"

const ImpairmentConfirmStep = (props: ImpairmentConfirmStepProps) => {
  const { handleSubmit } = props
  const { inspectionsStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm
  const impairments: ImpairmentInfoStepFields[] =
    formStore.fields?.impairments || []
  const damagesLength = impairments.filter(
    (impairment) => impairment.type === "damages"
  ).length
  const wearLength = impairments.filter(
    (impairment) => impairment.type === "wear_tear"
  ).length

  return (
    <>
      <StyledWrapper>
        <StyledLabel>
          Are you sure you want to create impairments with <br />
          <StyledAccent>{damagesLength} damages</StyledAccent> and{" "}
          <StyledAccent>{wearLength} wear&tear</StyledAccent>?
        </StyledLabel>
        <Caption weight={600}>
          After creating the impairments, an invoice will be generated that
          includes the lines from this report.
        </Caption>
      </StyledWrapper>
      <FormBottom
        label="Confirm"
        handleClick={handleSubmit}
        withPrevStep
        handlePrevClick={formStore.prevStep}
      />
    </>
  )
}

export default observer(ImpairmentConfirmStep)

const StyledAccent = styled.span`
  font-weight: 600;
`

const StyledLabel = styled(Caption)`
  padding: 0 10px;
  margin-bottom: 16px;
`

const StyledWrapper = styled.div`
  padding: 0 40px 24px;
  text-align: center;
`
