import React, { useEffect, useRef } from "react"
import * as Sentry from "@sentry/react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { observer } from "mobx-react"
import { ThemeProvider } from "@emotion/react"
import { theme } from "kui-basic"
import { KUISettings } from "kui-crm"
import { KUIUtilsSettings } from "kui-utils"
import { Loading } from "kui-complex"
import routes from "./routes"
import { RouteAuthTypes, RouteParams } from "./types/route"
import "react-datepicker/dist/react-datepicker.css"
import "kui-swiper/index.css"
import ErrorBoundary from "./components/common/ErrorBoundary"
import store, { MobxContext } from "./store"
import { mockServer } from "./__mocks__/browser"
import useStore from "./hooks/useStore"
import ProtectedRouteContent from "./components/common/ProtectedRouteContent"

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  Sentry.init({
    dsn: "https://07a5052bb80a46c1a92a3f972648a37d@sentry.maroom.ru/10",
    tracesSampleRate: 1.0,
  })
}

if (process.env.REACT_APP_MOCK_SERVER === "true") {
  mockServer.start()
}

KUISettings.setSettings({
  mapKey: process.env.REACT_APP_MAP_KEY || "",
  apiUrl: process.env.REACT_APP_API_URL,
  errorHandler: store.authStore.cleanAuthData,
})

KUIUtilsSettings.setSettings({
  runMode: process.env.NODE_ENV,
})

const renderRoute = (
  route: RouteParams,
  isAuthed: boolean,
  initialRoute: string
) => {
  switch (route.auth) {
    case RouteAuthTypes.loggedIn:
      return (
        <Route
          key={route.path}
          path={route.path}
          element={<ProtectedRouteContent route={route} />}
        />
      )
    case RouteAuthTypes.loggedOut:
      return (
        <Route
          key={route.path}
          path={route.path}
          element={
            !isAuthed ? (
              route.content
            ) : (
              <Navigate to={initialRoute || "/"} replace />
            )
          }
        />
      )
    default:
      return (
        <Route path={route.path} key={route.path} element={route.content} />
      )
  }
}

function App() {
  const {
    authStore: { isAuthed },
    appStore: { setInitialRoute, initialRoute },
    cabinetStore: { fetchAccessPermissions, loader },
  } = useStore()
  const pathRef = useRef(window.location.pathname)
  const searchRef = useRef(window.location.search)

  useEffect(() => {
    const path = pathRef.current
    const search = searchRef.current
    if (!path.includes("signin")) {
      setInitialRoute(`${path}${search || ""}`)
    }
    fetchAccessPermissions()
  }, [])

  if (loader.isLoading) return <Loading height="100vh" />

  return (
    <MobxContext.Provider value={store}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <BrowserRouter>
            <Routes>
              {routes.map((route) =>
                renderRoute(route, isAuthed, initialRoute)
              )}
            </Routes>
          </BrowserRouter>
        </ErrorBoundary>
      </ThemeProvider>
    </MobxContext.Provider>
  )
}

export default observer(App)
