import * as React from "react"
import { observer } from "mobx-react"
import { useState } from "react"
import ApartmentOverviewTab from "../../tabs/ApartmentOverviewTab"
import ApartmentExpenses from "../../../pages/ExpensesPage"
import ApartmentOwnershipTab from "../../tabs/ApartmentOwnershipTab"
import ApartmentDescriptionTab from "../../tabs/ApartmentDescriptionTab"
import ApartmentDocumentsTab from "../../tabs/ApartmentDocumentsTab"
import ApartmentInspectionsTab from "../../tabs/ApartmentInspectionsTab"
import useTabIndex from "../../../../../hooks/useTabIndex"
import useApartmentStore from "../../../store"
import ApartmentPromotion from "../../../pages/PromotionPage"
import ApartmentTabsSettings from "../ApartmentTabsSettings"
import PageTabsPanel from "../../../../../components/ui/PageTabsPanel"
import ProtectedTabs from "../../../../../components/common/ProtectedTabs"
import { ProtectedTabParams } from "../../../../../components/common/ProtectedTabs/types"

const tabs: ProtectedTabParams[] = [
  { label: "Overview", permission: "objectOverview" },
  { label: "Ownership", permission: "objectOwnership" },
  { label: "Inspections", permission: "objectInspections" },
  { label: "Descriptions", permission: "objectDescription" },
  { label: "Expenses", permission: "objectExpenses" },
  { label: "Promotion", permission: "objectPromotion" },
  { label: "Documents", permission: "objectDocuments" },
]

const paths = [
  `?tab=overview`,
  `?tab=ownership`,
  `?tab=inspections`,
  `?tab=descriptions`,
  `?tab=expenses`,
  `?tab=promotion`,
  `?tab=documents`,
]

const tabPanels = [
  <ApartmentOverviewTab />,
  <ApartmentOwnershipTab />,
  <ApartmentInspectionsTab />,
  <ApartmentDescriptionTab />,
  <ApartmentExpenses />,
  <ApartmentPromotion />,
  <ApartmentDocumentsTab />,
]

function ApartmentTabsPanel() {
  const { editor } = useApartmentStore()
  const activeTab = useTabIndex(paths)
  const [currentTab, setCurrentTab] = useState(activeTab)

  const handleTabChange = (e: any, tabIndex: number) => {
    setCurrentTab(tabIndex)
    editor.endEditing()
  }

  return (
    <ProtectedTabs withSpacing tabs={tabs} tabPanels={tabPanels}>
      <PageTabsPanel
        activeTab={Number(activeTab)}
        paths={paths}
        tabPanels={tabPanels}
        endComponent={<ApartmentTabsSettings activeTab={currentTab} />}
        onChange={handleTabChange}
      />
    </ProtectedTabs>
  )
}

export default observer(ApartmentTabsPanel)
