import React, { useState } from "react"
import { Button, Grid } from "kui-basic"
import { TariffGroupAutocomplete } from "kui-crm"
import { useWatch } from "react-hook-form"
import { EditIcon } from "kui-icon"
import styled from "@emotion/styled"
import { useToggle } from "kui-utils"
import TariffGroupReplaceModal from "../TariffGroupReplaceModal"
import { ReplaceableTariffGroupFieldProps } from "./types"
import useMeterStore from "../../store"

const ReplaceableTariffGroupField = (
  props: ReplaceableTariffGroupFieldProps
) => {
  const { form } = props
  const { editor, meterInfoStore } = useMeterStore()
  const [disabled, setDisabled] = useState(true)
  const [isModalOpen, openModal, closeModal] = useToggle()
  const meterType = useWatch({
    control: form.control,
    name: "type",
  })
  const tariffFilter = `${
    meterInfoStore.apartment?.id && meterInfoStore.resource !== "water"
      ? `&for_apartment=${meterInfoStore.apartment?.id}`
      : ""
  }${meterType ? `&type=${meterType}` : ""}`

  return (
    <Grid container alignItems="center">
      <StyledFieldWrapper>
        <TariffGroupAutocomplete
          resource={meterInfoStore.resource}
          label="Tariff group"
          form={form}
          disabled={disabled}
          filter={tariffFilter}
        />
      </StyledFieldWrapper>
      <StyledButton
        isCircle
        variant="transparent"
        size="s"
        disabled={!editor.isEditing || !disabled}
        onClick={openModal}
      >
        <EditIcon />
      </StyledButton>

      <TariffGroupReplaceModal
        open={isModalOpen}
        handleClose={closeModal}
        form={form}
        setIsDisabled={setDisabled}
      />
    </Grid>
  )
}

export default ReplaceableTariffGroupField

const StyledFieldWrapper = styled.div`
  flex-grow: 1;
`

const StyledButton = styled(Button)`
  margin-left: 16px;
`
